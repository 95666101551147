import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const useAOS = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      delay: 0,
      duration: 500,
      easing: 'ease',
      once: false,
      mirror: false,
    });

    // addAOSAttributes();
  }, []);
};

// const addAOSAttributes = () => {
//   const elements = document.querySelectorAll('.aos-fadeup');

//   elements.forEach(element => {
//     element.setAttribute('data-aos', 'fade-up');
//     element.setAttribute('data-aos-easing', 'linear');
//   });
// };

export default useAOS;