import React from "react";
import ServicesMasthead from "../sections/services/ServicesMasthead";
import OurServices2 from "../sections/services/OurServices2";
import SuiteSection from "../sections/services/SuiteSection";
import UmbrellaSection from "../sections/services/UmbrellaSection";
import DigitalPresence2 from "../sections/services/DigitalPresence2";

const ServicesPage = () => {
  return (
    <>
        <ServicesMasthead />
        <OurServices2 />
        <SuiteSection />
        <UmbrellaSection />
        <DigitalPresence2 />
    </>
  );
};

export default ServicesPage;
