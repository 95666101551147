import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import mastheadVideo from "../../../videos/Cubes.mp4";

const HeroSection = () => {
  return (
    <section
      className="hero-section d-flex align-items-center justify-content-center position-relative">
      <video
        src={mastheadVideo}
        autoPlay
        muted
        loop
        className="position-absolute w-100 h-100 object-fit-cover"
      ></video>
      <Container className="position-relative">
        <Row>
          <Col md={8} className="mx-auto text-center">
            <div className="hero-text">
              <div className="style-1 mb-4">
                <span className="color-orange-500">EnvisionX</span> is a new era in blockchain development, advancing{" "}
                <span className="color-orange-500">Web3 forward</span>
              </div>
              <p className="body-1 color-neutral-200 mb-4">
              Driven by innovation, we're shaping a better future for communities and generations.
              </p>
              <button className="primary-button-sm">Build Yours </button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
