import React from "react";
import { Row, Col } from "react-bootstrap";

const OurServices2 = () => {
  return (
    <section className="our-services-2-section px-3 px-lg-5 aos-fadeup">
      <Row className="gx-5">
        <Col md={6} className="mb-5 mb-lg-0">
          <p className="style-3">
            Empowering <span className="color-orange-500">Web3</span>. Easy,
            decentralized, and future-proof.
          </p>
        </Col>
        <Col md={6}>
          <Row>
            <Col sm={12} lg={4} className="mb-4 mb-lg-0">
              <h3 className="mb-4">Blockchain Development</h3>
              <ol>
                <li className="body-2 color-neutral-200 mb-2">
                  Blockchain Architecture Design
                </li>
                <li className="body-2 color-neutral-200 mb-2">
                  Smart Contract Development
                </li>
                <li className="body-2 color-neutral-200 mb-2">
                  Decentralized App Development
                </li>
                <li className="body-2 color-neutral-200 mb-2">
                  Integration with Existing Systems
                </li>
              </ol>
            </Col>
            <Col sm={12} lg={4} className="mb-4 mb-lg-0">
              <h3 className="mb-4">Cybersecurity</h3>
              <ol>
                <li className="body-2 color-neutral-200 mb-2">
                  Vulnerability Assessments
                </li>
                <li className="body-2 color-neutral-200 mb-2">
                  Penetration Testing
                </li>
                <li className="body-2 color-neutral-200 mb-2">
                  Threat Intelligence
                </li>
                <li className="body-2 color-neutral-200 mb-2">
                  Incident Response Planning
                </li>
              </ol>
            </Col>
            <Col sm={12} lg={4} className="mb-4 mb-lg-0">
              <h3 className="mb-4">Blockchain Development</h3>
              <ol>
                <li className="body-2 color-neutral-200 mb-2">
                  Front-end Development
                </li>
                <li className="body-2 color-neutral-200 mb-2">
                  Back-end Development
                </li>
                <li className="body-2 color-neutral-200 mb-2">
                  Database Management
                </li>
                <li className="body-2 color-neutral-200 mb-2">
                  DevOps and CI/CD Pipeline
                </li>
              </ol>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default OurServices2;
