import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundGif from "../../../videos/particles.gif";

const UmbrellaSection = () => {
  return (
    <section
      className="umbrella-section aos-fadeup"
      style={{
        backgroundImage: `url(${backgroundGif})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
      }}
    >
      <Container>
        <h1 className="style-2 mb-4">
          The <span className="color-orange-500"> Cybersecurity </span> Umbrella
        </h1>
        <p className="spacing-96 color-neutral-200 body-2">
          As cyber threats loom larger, protecting your digital assets is
          paramount. At envisionX, we aren't just about defense – we're
          proactive. We help you spot and neutralize vulnerabilities before
          anyone else can. Our cybersecurity services include:
        </p>
        <Row className="equal-cols">
          <Col sm={12} lg={6} className="mb-4 umbrella-col">
            <div className="umbrella-card ripple-effect-hover">
              <div className="">
                <p className="caption mb-3">Assessify</p>
                <h3 className="mb-3">
                  {" "}
                  <span> Vulnerability Assessments </span> Spotting Weaknesses
                  Before They Strike
                </h3>
                <p className="color-neutral-300 body-2">
                  As digital detectives, our team conducts comprehensive scans
                  of your digital landscape to identify potential
                  vulnerabilities. Our vulnerability assessments act like X-ray
                  vision, revealing weaknesses before they become threats. This
                  proactive approach ensures your operations are fortified and
                  secure.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6} className="mb-4 umbrella-col">
            <div className="umbrella-card ripple-effect-hover">
              <div className="">
                <p className="caption mb-3">PenTestify</p>
                <h3 className="mb-3">
                  {" "}
                  <span> Penetration Testing: </span> Simulating Attacks to
                  Strengthen Defenses{" "}
                </h3>
                <p className="color-neutral-300 body-2">
                  Our penetration testing immerses us in the mindset of cyber
                  intruders to uncover the tactics they might use against you.
                  By rigorously testing your defenses using the same methods as
                  potential attackers, we empower you with the knowledge needed
                  to fortify your digital fortress, ensuring it remains
                  impregnable.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6} className="mb-4 umbrella-col">
            <div className="umbrella-card ripple-effect-hover">
              <div className="">
                <p className="caption mb-3">ThreatIQ</p>
                <h3 className="mb-3">
                  {" "}
                  <span>Threat Intelligence</span> Staying Ahead of the Curve
                </h3>
                <p className="color-neutral-300 body-2">
                  Our threat intelligence serves as your advanced early warning
                  system. We deliver crucial insights into potential cyber
                  threats, acting like a high-tech radar that detects and
                  prepares you for potential attacks before they occur. With our
                  services, you stay one step ahead in the dynamic cybersecurity
                  game.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6} className="mb-4 umbrella-col">
            <div className="umbrella-card ripple-effect-hover">
              <div className="">
                <p className="caption mb-3">PlanGuard</p>
                <h3 className="mb-3">
                  {" "}
                  <span> Incident Response Planning</span> Ready for Anything
                </h3>
                <p className="color-neutral-300 body-2">
                  Every organization needs a robust defense strategy. Our
                  incident response planning prepares you for the unexpected,
                  equipping you with a comprehensive battle plan. We help you
                  anticipate and prepare for potential threats, ensuring that if
                  a cyber incident occurs, you can respond swiftly and
                  effectively to minimize any damage.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default UmbrellaSection;
