import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import mastheadVideo from "../../../videos/Cubes.mp4";

const ServicesMasthead = () => {
  return (
    <section className="hero-section d-flex align-items-center justify-content-center position-relative">
      <video
        src={mastheadVideo}
        autoPlay
        muted
        loop
        className="position-absolute w-100 h-100 object-fit-cover"
      ></video>
      <Container className="position-relative">
        <Row>
          <Col md={8} className="mx-auto text-center">
            <div className="hero-text">
              <button className="primary-button-sm"> PRODUCTS </button>
              <div className="style-1 mb-4">
                Pioneering the{" "}
                <span className="color-orange-500"> Future </span> of Blockchain
                Technology
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ServicesMasthead;
