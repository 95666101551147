import React from "react";
import AboutMasthead from "../sections/about/AboutMasthead";
import CodeCompany from "../sections/about/CodeCompany";
import CoreValues from "../sections/about/CoreValues";
import SpiritSection from "../sections/about/SpiritSection";
import JoinTeam from "../sections/about/JoinTeam";
import OurServices2 from "../sections/services/OurServices2";

const Homepage = () => {
  return (
    <>
      <AboutMasthead />
      <CodeCompany />
      <CoreValues />
      <SpiritSection />
      <JoinTeam />
      <OurServices2 />
      
    </>
  );
};

export default Homepage;
