import React from "react";

const DigitalPresence = () => {
  return (
    <div className="container aos-fadeup">
      <div
        className="digital-presence-section style-3 text-center position-relative"
        style={{
          
        }}
      >
        With EnvisionX by your side, you receive more than development—you get a digital masterpiece. Fast, reliable, and loved by users, your digital presence is poised to leap into the future.
      </div>
    </div>
  );
};

export default DigitalPresence;
