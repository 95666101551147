import React from "react";
import leftQuoteIcon from "../../../images/quote-icon-left.svg";
import rightQuoteIcon from "../../../images/quote-icon-right.svg";

const QuoteSection = ({ duration = 10000, reverse = false }) => {
  const quotes = [
    '"Innovating for a Better Tomorrow"',
    '"Empowering Communities with Blockchain"',
    '"Crafting Tomorrow\'s Digital Landscape"',
];

  return (
    <div
      className="quote-section section-padding position-relative"
      style={{
        "--duration": `${duration}ms`,
        "--direction": reverse ? "reverse" : "normal",
      }}
    >
      <div className="quote-container-wrapper">
        <div className="container quote-icons-container">
          <img
            src={leftQuoteIcon}
            alt="Left quote icon"
            className="left-quote-icon"
          />
          <img
            src={rightQuoteIcon}
            alt="Right quote icon"
            className="right-quote-icon"
          />
        </div>
        <div className="quote-container">
          {quotes.map((quote, index) => (
            <div className="quote style-3 me-5" key={index}>
              {quote}
            </div>
          ))}
          {quotes.map((quote, index) => (
            <div className="quote style-3 me-5" key={index + quotes.length}>
              {quote}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuoteSection;
