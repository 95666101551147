import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InnovationIcon from "../../../images/innovation-icon.svg";
import IntegrityIcon from "../../../images/integrity-icon.svg";
import CustomerFocusIcon from "../../../images/customer-focus-icon.svg";
import QualityObsessionIcon from "../../../images/quality-obsession-icon.svg";

const CoreValues = () => {
  return (
    <section className="core-values-section aos-fadeup">
      <Container>
        <p className="style-3 mb-4 text-center"> Our core values </p>
        <p className="body-1 text-center mb-5">
          Exceptional products are crafted by exceptional people. At our core,
          we celebrate diversity, and each team member personifies the values
          that define Pipedrive's culture.
        </p>
        <Row className="equal-cols">
          <Col sm={12} md={6} lg={3} className="mb-4 mb-lg-0">
            <div className="core-values-card ripple-effect-hover">
              <img
                src={InnovationIcon}
                alt="Innovation"
                className="d-flex justify-content-center mx-auto mb-4"
              />
              <h3 className="text-center mb-4">Innovation</h3>
              <p className="color-neutral-400 body-2 text-center">
              Think big and transform bold ideas into reality. Every project is a journey toward achieving your dreams.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3} className="mb-4 mb-lg-0">
            <div className="core-values-card ripple-effect-hover">
              <img
                src={IntegrityIcon}
                alt="Innovation"
                className="d-flex justify-content-center mx-auto mb-4"
              />
              <h3 className="text-center mb-4">Integrity</h3>
              <p className="color-neutral-400 body-2 text-center">
              Fair play, transparent minds. We prioritize your interests—no secrets, only integrity.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3} className="mb-4 mb-lg-0">
            <div className="core-values-card ripple-effect-hover">
              <img
                src={CustomerFocusIcon}
                alt="Innovation"
                className="d-flex justify-content-center mx-auto mb-4"
              />
              <h3 className="text-center mb-4">Customer Focus</h3>
              <p className="color-neutral-400 body-2 text-center">
              More than business—it's partnership. Your goals are ours. Let's achieve them together and enjoy the journey!
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3} className="mb-4 mb-lg-0">
            <div className="core-values-card ripple-effect-hover">
              <img
                src={QualityObsessionIcon}
                alt="Innovation"
                className="d-flex justify-content-center mx-auto mb-4"
              />
              <h3 className="text-center mb-4">Quality Obsession</h3>
              <p className="color-neutral-400 body-2 text-center">
              Every project receives meticulous attention and leaves our desk only when it reaches excellence.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CoreValues;
