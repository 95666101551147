import React from "react";
import logo from "../../images/logo.svg";
import { Container } from "react-bootstrap";
import YoutubeIcon from "../../images/youtube-icon.svg";
import DiscordIcon from "../../images/discord-icon.svg";
import GithubIcon from "../../images/github-icon.svg";
import TwitterIcon from "../../images/twitter-icon.svg";
import TelegramIcon from "../../images/telegram-icon.svg";
import RedditIcon from "../../images/reddit-icon.svg";
import GlobeIcon from "../../images/globe-icon.svg";

const Footer = () => {
  const socialMediaLinks = [
    { href: "https://www.youtube.com/", src: YoutubeIcon, alt: "Youtube" },
    { href: "https://discord.com/", src: DiscordIcon, alt: "Discord" },
    { href: "https://github.com", src: GithubIcon, alt: "Github" },
    { href: "https://twitter.com/", src: TwitterIcon, alt: "Twitter" },
    { href: "https://t.me/", src: TelegramIcon, alt: "Telegram" },
    { href: "https://www.reddit.com/", src: RedditIcon, alt: "Reddit" },
  ];
  return (
    <footer className="position-relative">
      <div className="footer-strip">
        <h3 className="text-center">
          Envisioning a groundbreaking project? Let's make it happen! Your
          Vision, Our Passion! Contact us NOW to Ignite the Future{" "}
        </h3>
      </div>
      <div className="footer-container">
        <Container>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="footer-left">
              <div className="logo mb-3">
                <img src={logo} alt="EnvisionX" />
              </div>
              <div className="social-media-icons mb-3">
                {socialMediaLinks.map((link, index) => (
                  <a key={index} href={link.href} className="me-3">
                    <img src={link.src} alt={link.alt} />
                  </a>
                ))}
              </div>
              <div className="language-container d-none d-md-block d-lg-none mb-4 mb-md-0">
                  <img
                    src={GlobeIcon}
                    alt=""
                    className="d-inline position-relative me-2"
                    style={{ top: "5px" }}
                  />
                  <span className="body-2 text-uppercase color-neutral-500">
                    {" "}
                    en{" "}
                  </span>
                </div>
              <div className="copyright caption color-neutral-600 d-none d-lg-block">
                © 2023, EnvisionX. All rights reserved.
              </div>
            </div>
            <div className="footer-right">
              <div className="d-flex footer-links-container">
                <ul className="links me-0 me-lg-5 w-100">
                  <p className="body-1 mb-4 d-none d-md-block"> Links </p>
                  <li className="body-3 mb-4">
                    <a href="/" className="color-neutral-200">
                      Home
                    </a>
                  </li>
                  <li className="body-3 mb-4">
                    <a href="/services" className="color-neutral-200">
                      Services
                    </a>
                  </li>
                  <li className="body-3 mb-4">
                    <a href="/about-us" className="color-neutral-200">
                      About us
                    </a>
                  </li>
                  <li className="body-3 mb-4">
                    <a href="/contact-us" className="color-neutral-200">
                      Contact us
                    </a>
                  </li>
                </ul>
                <div className="language-container d-md-none d-lg-block mb-4 mb-md-0">
                  <img
                    src={GlobeIcon}
                    alt=""
                    className="d-inline position-relative me-2"
                    style={{ top: "0px" }}
                  />
                  <span className="body-2 text-uppercase color-neutral-500">
                    {" "}
                    en{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright caption color-neutral-600 d-block d-lg-none text-center">
                © 2023, EnvisionX. All rights reserved.
              </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
