import React from 'react';

const ServiceCard = ({ content, stripColor }) => {
    return (
        <div className="service-card">
            <div className="service-card-strip mb-3" style={{"backgroundColor": stripColor}}></div>
            <h3>{content}</h3>
        </div>
    );
};

export default ServiceCard;
