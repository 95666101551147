import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const ContactForm = () => {
  return (
    <section className="contact-form-section">
      <Container>
        <Row>
          <Col sm={12} md={12} lg={6}>
            <h2 className="style-1 mb-5">Interested? Let’s talk!</h2>
            <p className="color-neutral-50 body-1 mb-5">
              Just fill this form and we will contact you promptly to discuss
              your project. Hate forms? Drop us a message at{" "}
              <a href="mailto:info@waves.com">
                {" "}
                <span className="color-orange-500"> info@waves.com </span>{" "}
              </a>
              or{" "}
              <a href="mailto:hello@waves.com">
                {" "}
                <span className="color-orange-500"> hello@waves.com </span>
              </a>
            </p>
            <form
              action="mailto:hello@waves.com?subject=Contact Form Submission" method="post" enctype="text/plain"
            >
              <div className="mb-4">
                <label htmlFor="name" className="form-label label">
                  Your Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter your Name"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="form-label label">
                  Your Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter your email ID"
                />
              </div>
              <div className="mb-5">
                <label htmlFor="query" className="form-label label">
                  About Your Query
                </label>
                <textarea
                  className="form-control"
                  id="query"
                  name="query"
                  placeholder="Tell us about your project"
                />
              </div>
              <button type="submit" className="primary-button">
                Send Request
              </button>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactForm;
