import React from "react";
import ContactForm from "../sections/contact/ContactForm";
import OurServices2 from "../sections/services/OurServices2";

const Homepage = () => {
  return (
    <>
      <ContactForm />
      <OurServices2 />
    </>
  );
};

export default Homepage;
