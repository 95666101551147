import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import SpiritIcon1 from "../../../images/spirit-icon-1.svg";
import SpiritIcon2 from "../../../images/spirit-icon-2.svg";
import SpiritIcon3 from "../../../images/spirit-icon-3.svg";
import SpiritIcon4 from "../../../images/spirit-icon-4.svg";
import DnaIcon from "../../../images/dna-icon.svg";
import CollaborationIcon from "../../../images/collaboration-icon.svg";

const SpiritSection = () => {
  return (
    <section className="spirit-section aos-fadeup">
      <Container>
        <Row className="equal-cols">
          <Col sm={12} lg={6}>
            <div className="d-flex justify-content-end mb-4">
              <img src={SpiritIcon1} alt="icon 1" className="me-4" />
              <img src={SpiritIcon2} alt="icon 2" className="me-4" />
              <img src={SpiritIcon3} alt="icon 3" className="me-4" />
              <img src={SpiritIcon4} alt="icon 4" className="me-4" />
            </div>
            <div className="spirit-card mb-5">
              <p className="style-3 mb-4">
                <span className="color-orange-500"> Our Playground: </span>{" "}
                Where Web3 Meets Innovation
              </p>
              <h3 className="color-neutral-200 mb-4">
                Revolutionizing the Web with Web3 Magic
              </h3>
              <p className="body-1 color-neutral-400">
                Ever wondered what happens when technology meets magic? Web3 is
                not just a concept for us; it's the secret sauce to redefine
                digital landscapes. Join us in the era where decentralized
                applications and blockchain create not just projects but digital
                symphonies.
              </p>
            </div>
            <div className="spirit-card mb-4 mb-lg-0">
              <p className="style-3 mb-4">
                <span className="color-orange-500">
                  {" "}
                  Guardians of Cybersecurity{" "}
                </span>
              </p>
              <h3 className="color-neutral-200 mb-4">
                Defenders of Digital Realms
              </h3>
              <p className="body-1 color-neutral-400">
                Cybersecurity isn't a choice; it's our responsibility. Picture
                us as the digital knights guarding your data castle. From
                vulnerability assessments to incident response planning, we're
                the guardians of your digital universe.
              </p>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <div className="spirit-card">
              <p className="style-3 mb-5">
                {" "}
                <span className="color-orange-500">
                  {" "}
                  The EnvisionX Spirit:{" "}
                </span>{" "}
                We're More Than Code{" "}
              </p>
              <div className="spirit-mini-card mb-5">
                <img src={DnaIcon} className="mb-4" alt="Icon 1" />
                <h3 className="color-neutral-200 mb-4">
                  Innovation in Our DNA
                </h3>
                <p className="color-neutral-400 body-1">
                  We don't just code; we dream, we innovate, and we redefine.
                  Innovation isn't a buzzword; it's a way of life. Your
                  projects? They're not just tasks; they're opportunities for
                  groundbreaking ideas.
                </p>
              </div>
              <div className="spirit-mini-card">
                <img src={CollaborationIcon} className="mb-4" alt="Icon 1" />
                <h3 className="color-neutral-200 mb-4">Collaboration is Key</h3>
                <p className="color-neutral-400 body-1">
                  We're not just a team; we're a digital family. Collaboration
                  fuels our creativity. Your ideas blend with ours, creating a
                  cocktail of brilliance that propels us forward.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SpiritSection;
