import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const AboutMasthead = () => {
  return (
    <section className="hero-section d-flex align-items-center justify-content-center position-relative">
      <Container className="position-relative">
        <Row>
          <Col md={8} className="mx-auto text-center">
            <div className="hero-text">
              <div className="style-1 mb-4">
                Our <span className="color-orange-500"> vision </span> is to
                make work inspiring and fulfilling
              </div>
              <p className="body-1 color-neutral-200 mb-4">
              HRLInk is a cloud-based HR system designed to simplify and streamline HR processes. Grove HR is a powerful tool that can help SMB businesses manage their HR operations with ease.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutMasthead;
