import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const DigitalPresence2 = () => {
  return (
    <section className="digital-presence-2-section aos-fadeup">
      <Container>
        <h2 className="style-2 mb-4">
          Empowering{" "}
          <span className="color-orange-500"> Your Digital Presence </span>{" "}
        </h2>
        <p className="color-white mb-sm-5 spacing-96">
          Our full-stack development services are your express ticket to
          supercharging your web and mobile applications. But we don't stop at
          building; we're in the business of crafting experiences that are
          swift, reliable, and cherished by users. Our toolkit boasts an array
          of powerful tools, including:
        </p>
        <Row>
          <Col sm={12} lg={6} className="digital-presence-col">
            <div className="digital-presence-card">
              <div className="d-flex justify-content-between mb-4">
                <h3 className="color-white">CodeCraft</h3>
                <a href="/contact-us" className="color-white primary-button-sm">
                  Contact
                </a>
              </div>
              <div>
                <p className="body-1 color-neutral-200 mb-4">
                  Front-end Development: Crafting the User's Digital Journey
                </p>
                <p className="body-2 color-neutral-300">
                  Our front-end development team transforms HTML, CSS, and
                  JavaScript into the artist's canvas, crafting the look, feel,
                  and overall user experience. The result is a visually stunning
                  and seamless digital journey that keeps users engaged and
                  delighted.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6} className="digital-presence-col">
            <div className="digital-presence-card">
              <div className="d-flex justify-content-between mb-4">
                <h3 className="color-white">Backendify</h3>
                <a href="/contact-us" className="color-white primary-button-sm">
                  Contact
                </a>
              </div>
              <div>
                <p className="body-1 color-neutral-200 mb-4">
                  Back-end Development: The Engine Room of Your Application
                </p>
                <p className="body-2 color-neutral-300">
                  Our back-end development team is the powerhouse behind your
                  application, constructing the core engine that drives it. With
                  expertise in Python, Java, and PHP, we ensure your application
                  operates smoothly, efficiently, and reliably.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6} className="digital-presence-col">
            <div className="digital-presence-card">
              <div className="d-flex justify-content-between mb-4">
                <h3 className="color-white">DataCraft</h3>
                <a href="/contact-us" className="color-white primary-button-sm">
                  Contact
                </a>
              </div>
              <div>
                <p className="body-1 color-neutral-200 mb-4">
                  Database Design: The Bedrock of Your Digital World
                </p>
                <p className="body-2 color-neutral-300">
                  Every digital system requires a solid foundation, and that’s
                  precisely what our expert database design delivers. We
                  meticulously craft and manage the foundation of your digital
                  world, ensuring your data is secure, well-organized, and
                  readily accessible to meet all your needs.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6} className="digital-presence-col">
            <div className="digital-presence-card">
              <div className="d-flex justify-content-between mb-4">
                <h3 className="color-white">DevOpsify</h3>
                <a href="/contact-us" className="color-white primary-button-sm">
                  Contact
                </a>
              </div>
              <div>
                <p className="body-1 color-neutral-200 mb-4">
                  DevOps and CI/CD: The Fast Lane to Deployment
                </p>
                <p className="body-2 color-neutral-300">
                  Our DevOps and CI/CD pipeline development is a superhighway
                  for your development process. We streamline your pipeline to
                  enhance speed and efficiency, ensuring your applications move
                  from concept to deployment in record time.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default DigitalPresence2;
