import { useEffect } from 'react';

const useRippleEffect = () => {
  useEffect(() => {
    const cards = document.querySelectorAll('.ripple-effect-hover');

    cards.forEach(card => {
      let rippleTriggered = false;

      card.addEventListener('mouseenter', (event) => {
        if (!rippleTriggered) {
          createRippleHover(card, event);
          rippleTriggered = true;
        }
      });

      card.addEventListener('mouseleave', () => {
        rippleTriggered = false;
      });
    });

    function createRippleHover(card, event) {
      const rect = card.getBoundingClientRect();
      const size = Math.max(rect.width, rect.height);

      const x = event.pageX - rect.left - window.scrollX - size / 2;
      const y = event.pageY - rect.top - window.scrollY - size / 2;

      const ripple = document.createElement('span');
      ripple.classList.add('ripple');
      ripple.style.width = ripple.style.height = `${size}px`;
      ripple.style.left = `${x}px`;
      ripple.style.top = `${y}px`;

      card.appendChild(ripple);

      setTimeout(() => {
        ripple.remove();
      }, 600);
    }
  }, []);
};

export default useRippleEffect;