import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundGif from "../../../videos/distorted.gif";

const SuiteSection = () => {
  const suiteCards = [
    {
      title: "Cryptogeny",
      body: `Our expertise in blockchain architecture design goes beyond
      creating blockchains; we're the architects of secure, efficient digital
      ecosystems. </span> Think of it as crafting a blueprint for a high-tech city
      where data flows securely, transactions occur seamlessly,
      and trust is innate. Our experts assemble the digital
      puzzle, ensuring a robust, customized blockchain tailored to
      your needs.`,
      listItems: ["Blockchain Architecture Design"],
      color: "#1DA1BB",
    },
    {
      title: "Contractify",
      body: `Imagine contracts that don't just sit in dusty folders but
      execute themselves with precision. That's the magic of smart
      contract development. We're here to transform your agreements into
      self-executing code. It's like giving life to your business promises. With our
      expertise, you can automate complex processes, eliminate the
      need for intermediaries, and ensure that every step of your
      contract is transparent and secure.`,
      listItems: ["Smart Contract Development"],
      color: "#1DA1BB",
    },
    {
      title: "DAppify",
      body: `Apps that don't rely on a central authority or a single point of failure – that's the beauty of decentralized application development. We're the pioneers in creating apps that harness the power of decentralization. These apps are resilient, secure, and put users in control of their data. Whether you're looking to create a new decentralized app or transition an existing one, our team is at the forefront of this exciting technology.`,
      listItems: ["Decentralized Application Development"],
      color: "#1DA1BB",
    },
    {
      title: "Systemize",
      body: `The future of business lies in seamlessly merging the power of blockchain into your existing systems. This isn't just an add-on; it's a transformation. We're here to bridge your current digital world with the innovations of blockchain. Picture your operations becoming more transparent, efficient, and secure. Our experts ensure that this integration happens smoothly, without disrupting your day-to-day business, and brings the advantages of blockchain technology to
      your fingertips.`,
      listItems: ["Integration with Existing Systems"],
      color: "#1DA1BB",
    },
  ];
  return (
    <section
      className="suite-section aos-fadeup"
      style={{
        backgroundImage: `url(${backgroundGif})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container>
        <Row>
          <Col md={6}>
            <p className="label mb-3"> START HERE. BUILD ANYTHING. </p>
            <p className="style-3 mb-4">
              Unlock the full potential of Web3 with our comprehensive{" "}
              <span className="color-orange-500"> product suite </span>
            </p>
            <p className="body-2 mb-5 mb-lg-0">
              {" "}
              Blockchain technology redefines business data management. Our
              leading blockchain developers are pioneering this transformation,
              propelling you to the forefront of innovation.
            </p>
          </Col>
          <Col md={6}>
            <Row className="suite-cards-container equal-cols">
              <Col sm={12} md={6} className="mb-3 suite-col-1">
                <div className="suite-card ripple-effect-hover">
                  <h3 className="mb-4"> Cryptogeny </h3>
                  <p className="body-2 color-neutral-300 mb-4">
                    Our expertise in blockchain architecture design extends
                    beyond mere creation;{" "}
                    <span>
                      {" "}
                      we architect secure and efficient digital ecosystems.{" "}
                    </span>{" "}
                    Imagine designing a blueprint for a high-tech city—where
                    data flows securely, transactions proceed seamlessly, and
                    trust is built into the framework. Our experts meticulously
                    assemble this digital infrastructure, crafting a robust,
                    tailored blockchain solution that meets your unique needs.
                  </p>
                  <ul>
                    <li> Blockchain Architecture Design </li>
                  </ul>
                </div>
              </Col>
              <Col sm={12} md={6} className="mb-3 suite-col-2">
                <div className="suite-card ripple-effect-hover">
                  <h3 className="mb-4"> Contractify </h3>
                  <p className="body-2 color-neutral-300 mb-4">
                    Imagine contracts that execute themselves with
                    precision—this is the transformative power of smart contract
                    development.{" "}
                    <span>
                      {" "}
                      We specialize in turning traditional agreements into
                      self-executing digital contracts, bringing your business
                      commitments to life.{" "}
                    </span>{" "}
                    With our expertise, you can streamline complex processes,
                    remove intermediaries, and ensure every transaction is
                    transparent and secure.
                  </p>
                  <ul>
                    <li> Smart Contract Development </li>
                  </ul>
                </div>
              </Col>
              <Col sm={12} md={6} className="mb-3 suite-col-3">
                <div className="suite-card ripple-effect-hover">
                  <h3 className="mb-4"> DAppify </h3>
                  <p className="body-2 color-neutral-300 mb-4">
                    Experience apps that operate independently of central
                    authorities and eliminate single points of failure—this is
                    the essence of decentralized application development.{" "}
                    <span>
                      {" "}
                      We are at the forefront of crafting resilient, secure apps
                      that empower users with full control over their data.{" "}
                    </span>{" "}
                    Whether you aim to launch a new dApp or transition an
                    existing one to a decentralized platform, our team leads the
                    way in this groundbreaking technology, ensuring robustness
                    and user privacy across various industries.
                  </p>
                  <ul>
                    <li> DApp Development </li>
                  </ul>
                </div>
              </Col>
              <Col sm={12} md={6} className="mb-3 suite-col-4">
                <div className="suite-card ripple-effect-hover">
                  <h3 className="mb-4"> Systemize </h3>
                  <p className="body-2 color-neutral-300 mb-4">
                    Transform your business by seamlessly integrating blockchain
                    technology into your existing systems. This isn't merely an
                    add-on—it's a comprehensive upgrade. <span> We bridge the gap
                    between your current operations and the future of digital
                    innovation, enhancing transparency, efficiency, and
                    security. </span> Our experts ensure that this integration is smooth
                    and non-disruptive, enabling you to harness the full
                    advantages of blockchain without interrupting your daily
                    workflow.
                  </p>
                  <ul>
                    <li> Existing Systems Integration </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SuiteSection;
