import React from 'react';

const WelcomeSection = () => {
  return (
    <div className="container aos-fadeup">
      <section className="welcome-section">
        <div className='style-3'>
          Welcome to <span className="color-orange-500">EnvisionX</span>, where we're pioneering the next generation of digital infrastructure with advanced, <span className="color-orange-500">secure technologies</span>. 🚀 Our team of innovators and experts expertly integrates technology with cybersecurity, crafting secure, scalable, and <span className="color-orange-500">unique solutions tailored to your future needs.</span>.
        </div>
      </section>
    </div>
  );
};

export default WelcomeSection;
