import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InnovationIcon from "../../../images/innovation.svg";
import IntegrityIcon from "../../../images/integrity.svg";
import CustomerFocusIcon from "../../../images/customer_focus.svg";
import QualityObsessionIcon from "../../../images/quality_obsession.svg";

const ValuesSection = () => {
  const valuesCards = [
    {
      icon: InnovationIcon,
      title: "Innovation",
      content:
        "Think big and transform bold ideas into reality. Every project is a journey toward achieving your dreams.",
    },
    {
      icon: IntegrityIcon,
      title: "Integrity",
      content:
        "Fair play, transparent minds. We prioritize your interests—no secrets, only integrity.",
    },
    {
      icon: CustomerFocusIcon,
      title: "Customer Focus",
      content:
        "More than business—it's partnership. Your goals are ours. Let's achieve them together and enjoy the journey!",
    },
    {
      icon: QualityObsessionIcon,
      title: "Quality Obsession",
      content:
        "Every project receives meticulous attention and leaves our desk only when it reaches excellence.",
    },
  ];
  return (
    <div className="values-section-container mx-0 mx-md-4 aos-fadeup">
      <Container>
        <Row>
          <Col sm={12} md={12} lg={6} className="mb-4 mb-lg-0">
            <p className="label color-neutral-50 mb-2 text-uppercase">
              Our Values
            </p>
            <div className="style-1 color-neutral-50 mb-3 mb-md-4 mb-lg-5">
              <span className="color-orange-500">Innovators</span> at Heart,
              Pioneers in <span className="color-orange-500">Tech</span>.
            </div>
            <p className="body-1 color-neutral-200">
              We're the rebels, the forward-thinkers, and the architects of
              what's next. EnvisionX is built on these core values.
            </p>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <Row className="equal-cols">
              {valuesCards.map((card, index) => (
                <Col sm={6} md={6} key={index}>
                  <div className="values-card mb-4 ripple-effect-hover">
                    <img
                      src={card.icon}
                      alt={card.title}
                      className="d-flex mx-auto w-25 mb-2"
                    />
                    <h3 className="text-center color-neutral-white mb-2">
                      {card.title}
                    </h3>
                    <p className="body-2 color-neutral-400">{card.content}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ValuesSection;
