import React from "react";
import HeroSection from "../sections/homepage/HeroSection";
import WelcomeSection from "../sections/homepage/WelcomeSection";
import OurServices from "../sections/homepage/OurServices";
import QuoteSection from "../sections/homepage/QuoteSection";
import ValuesSection from "../sections/homepage/ValuesSection";
import DigitalPresence from "../sections/homepage/DigitalPresence";

const Homepage = () => {
  return (
    <>
      <HeroSection />
      <WelcomeSection />
      <OurServices />
      <QuoteSection />
      <ValuesSection />
      <DigitalPresence />
    </>
  );
};

export default Homepage;
